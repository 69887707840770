



































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  metaInfo: metaInfo({
    title: 'Ochrana Osobních Údajů | Kakadoo',
    url: buildUrl('/ochrana-osobnich-udaju'),
    description: 'Ceny dodávek již od 590 Kč/den. Žádnými skrytými poplatky vás nepřekvapíme.'
  })
})
export default class Privacy extends Vue {
  showSettings() {
    store.dispatch('toggleCookieSettings', true);
  }
}
